<template>
  <div class="background d-flex align-items-center justify-content-center vh-100">
    <PlatformLogo variant="iconmark-light" :width="200" />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import PlatformLogo from '@/components/icon/PlatformLogo';

export default defineComponent({
  layout: 'plain',
  auth: 'guest',
  components: { PlatformLogo },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.background {
  background-color: $primary-2;
}
</style>
